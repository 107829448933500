.normal-text {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 500;
    margin: 0px;
}

.bold-text {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 900;
    margin: 0px;
}

.normal-text-italic {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 500;
    margin: 0px;
    font-style: italic;
}

.bold-text-italic {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 900;
    margin: 0px;
    font-style: italic;
}

.small-text {
    font-size: 0.6rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 500;
    margin: 0px;
}

.normal-text-strong {
    font-size: 0.8rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 900;
    margin: 0px;
}

.normal-text-white {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-weight: 500;
    margin: 0px;
}

.bigger-text {
    font-size: 1.2rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 900;
    margin: 0px;
    padding-right: 20px;
}

.coach-page-style {
    width: 98%;
    margin: 0 auto;
}

.coach-page-style .page-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
}

.page-title-bar {
    display: flex;
    align-items: center;
    gap: 24px;
}

.page-title-bar svg path {
    fill: white;
}

.page-title {
    font-family: 'DM Sans', sans-serif;
    font-size: 1.4rem;
    color: #1a1b1d;
    font-weight: 700;
    margin: 0px;
    min-width: 200px;
}

.coach-page-style .page-tab-container {
    display: flex;
    align-items: center;
    gap: 32px;
    padding-left: 56px;
}

.page-tab-container.settings-page {
    padding-left: 0px;
}

.page-tab-container .page-tab-style {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
    cursor: pointer;
}

.page-tab-title {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.9rem;
    color: #1a1b1d;
    font-weight: 600;
    margin: 0px;
}

.page-tab-style .selected-line {
    width: 100%;
    height: 2px;
    background-color: #17375e;
}

.page-tab-style .unselected-line {
    width: 100%;
    height: 2px;
    background-color: #f8f8f8;
}

.loading-circle {
    width: 100%;
    height: 80%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaders-column-title {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
}

.sidebar-game-count {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.9rem;
    color: #1a1b1d;
    font-weight: 600;
    margin: 0px;
}

.select-narrator,
.leaders-item-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.8rem;
    color: #1a1b1d;
    font-weight: 500;
    margin: 0px;
}

.menu-item {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.7rem;
    color: #1a1b1d;
    font-weight: 600;
    margin: 0px;
}

.chart-title {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.9rem;
    color: #1a1b1d;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.chart-bar-text {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-weight: 500;
    margin: 0px;
    text-align: center;
}

.chart-primary-text {
    font-size: 0.7rem;
    font-family: 'DM Sans', sans-serif;
    color: #1a1b1d;
    font-weight: 500;
    margin: 0px;
    text-align: right;
    padding-right: 8px;
}

.tab-page {
    background: #ffffff;
    display: flex;
    width: 100%;
    min-height: 65vh;
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px 10px;
}

.tab-page.settings-profile {
    gap: 48px;
}

.tab-page .settings-password-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.settings-password-container .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 32px;
}

.tab-page.settings-render {
    justify-content: center;
}

.settings-render .render-container-split {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 0 25px;
}

.render-container-split .render-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.render-container .render-item {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.render-item .render-item-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 1rem;
    color: #0a7304;
    font-weight: 700;
    margin: 0px;
    padding-left: 20px;
}

.render-item .render-item-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 35px;
    border: 1px solid #0a7304;
    border-radius: 12px;
    width: 400px;
}

.render-item-container img {
    width: 270px;
    height: 180px;
}

.tab-page.settings_representative {
    gap: 10px;
    justify-content: center;
}

.settings_academy_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.settings_academy_container p:first-child {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
}

.settings_academy_container .academy_section {
    padding: 16px 12px;
    border: 1px solid #0a7304;
    border-radius: 8px;
    width: 240px;
    height: 60vh;
}

.settings_academy_container .academy_items_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 55vh;
    overflow-y: auto;
}

.settings_academy_container .academy_section .academy_items_container .academy_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #0a7304;
    cursor: pointer;
}

.settings_academy_container .academy_section .academy_items_container .academy_item svg path {
    fill: black;
}

.settings_academy_container .academy_team_section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 260px;
    height: 60vh;
    border-radius: 8px;
    padding: 16px 12px;
    border: 1px solid #0a7304;
}

.settings_academy_container .academy_team_section .team_season_container {
    text-align: center;
    width: 100%;
}

.settings_academy_container .academy_team_section .academy_team_items_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 50vh;
    overflow-y: auto;
}

.settings_academy_container .academy_team_section .academy_team_items_container .academy_team_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #0a7304;
    cursor: pointer;
}

.settings_academy_container .academy_team_section .academy_team_items_container .academy_team_item svg path {
    fill: black;
}

.settings_academy_container .team_game_section {
    padding: 16px 12px;
    border: 1px solid #0a7304;
    border-radius: 8px;
    width: 720px;
    height: 60vh;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item p:first-child {
    font-size: 0.7rem;
    width: 72px;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item .game_section {
    display: flex;
    align-items: center;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item .game_section img:first-child {
    width: 30px;
    height: 30px;
    margin: 4px 4px;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item .game_section p:nth-child(2) {
    width: 20px;
    text-align: center;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item .game_section p:nth-child(3) {
    width: 180px;
}

.settings_academy_container .team_game_section .academy_items_container .team_game_item .game_section img:last-child {
    width: 18px;
}

.horizontal-4 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.edit_video_player_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.edit_video_player_container .video_player_sub_container {
    width: 100%;
    margin: auto;
    min-width: 500;
    position: relative;
}

.edit_video_player_container .video_player_sub_container .video_player_section {
    width: 80%;
    margin: auto;
}

@media only screen and (min-width: 720px) {
    .edit_video_player_container .video_player_sub_container .video_player_section {
        width: 98%;
    }
}

@media only screen and (max-width: 600px) {
    .edit_video_player_container .video_player_sub_container .video_player_section {
        width: 90%;
    }
}

input,
textarea {
    font-family: 'DM Sans', sans-serif;
}
