@media screen and (max-width: 1200px){
    .container{
        max-width: 1140px;
    }
    .music_journey_row{
        width: 100%;
    }
}
@media screen and (max-width: 1100px){
    .all_course_row .music-content, .all_course_row .music_animation{
        height: auto;
    }
    .music-content h3, .music_animation h3{
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 30px;
    }
    .paregraph-music ul li{
        gap: 15px;
    }
    .music_journey_row.all_course_row .music-content, .music_journey_row.all_course_row .music_animation{
        padding: 30px 30px;
    }
    .music_journey_row.all_course_row .music_animation .custom-btn{
        top: 90%;
    }
}
@media screen and (max-width: 800px){
    .navbar-toggler {
        display: block;
        background-color: #842d24;
        border-radius: 4px;
    }
    .navbar-toggler span{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
    .navbar-collapse{
        display: none;
        position: absolute;
        top: 70px;
        width: 30%;
        overflow: hidden;
        right: -100%;
        background-color: #151515;
        padding: 15px;
        height: 100vh;
        box-shadow: -10px 10px 20px #00000045;
        transition: all 0.5s ease-in-out;
    }
    .navbar-collapse.show {
        display: flex;
        right: 0;
    }
    .navbar-collapse ul {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
    }
    .navbar-nav .nav-item:nth-child(1), .navbar-nav .nav-item:nth-last-child(1),
    .navbar-nav .menu-item:nth-child(1),
.navbar-nav .menu-item:nth-last-child(1){
        margin: 0;
    }

    .banner-outer h1 {
        text-align: center;
        font-size: 80px;
        line-height: 80px;
        width: 100%;
    }
    .banner-outer p{
        margin: 30px 0;
        text-align: center;
        width: 100%;
        font-size: 21px;
        line-height: 35px;
    }
    .banner-outer .container{
        text-align: center;
    }
    .about_section .custom-row{
        height: auto;
        flex-wrap: wrap;
    }
    .about_content{
        width: 100%;
        text-align: center;
        padding: 30px 0;
    }
    .about_image{
        width: 100%;
        position: revert;
    }
    .title{
        font-size: 80px;
        line-height: 80px;
    }
    .content{
        width: 100%;
        margin: 30px 0;
    }
    .course_section h2 {
        font-size: 100px;
        line-height: 80px;
        margin-top: 30px;
    }
    .course_section{
        padding: 50px 0;
        margin-top: 50px;
    }
    .course_section p{
        width: 100%;
        margin: 50px 0;
    }
    .title-row{
        margin-top: 30px;
    }
    .title-row .title{
        text-align: center;
        width: 100%;
        font-size: 80px;
        line-height: 80px;
    }
    .title-row .content{
        width: 100%;
            font-size: 21px;
    line-height: 35px;
    text-align: center;
    }
    .working_days{
        margin: 30px 0;
    }
    .working_days h3{
        font-size: 45px;
    }
    .working_days h3 small{
        font-size: 30px;
    }
    .goals_future_section{
        padding: 50px 0;
    }
    .goals_future_section .title,
    .musical_journey_section .title{
        width: 100%;
        font-size: 80px;
        line-height: 80px;
    }
    .musical_journey_section .title{
        margin-bottom: 30px;
    }
    .music-content, .music_animation{
        padding: 15px;
    }
    .home_musicAnimation.music_animation h3 {
        color: #151515;
        font-size: 30px;
        line-height: 30px;
    }
    .home_musicAnimation.music_animation .sub-title {
        font-size: 25px;
        margin-top: 10px;
    }
    .home_musicAnimation.music_animation .paregraph-music .content {
        width: 100%;
        padding-top: 20px;
        font-size: 15px;
        line-height: 25px;
    }
    .music-content, .music_animation{
        border-radius: 20px;
    }
    .music_animation .custom-btn{
        position: relative;
        bottom: 0;
    }
    .footer-logo{
        width: 155px;
    }
    footer{
        padding: 50px 0;
    }
    .course-banner {
        padding: 50px 0;
        text-align: center;
    }
    .main-title{
        width: 100%;
        font-size: 65px;
        line-height: 65px;
    }
    .course-banner .content{
        width: 100%;
        margin: 30px 0;
    }

    .course_content_row img{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    .inner_course_page .content{
        width: 100%;
    }
    .about_us_section .music_animation{
        width: 50%;
        margin-right: 0;
    }
    .form_outer{
        width: 100%;
    }
    .contact-us-page-heading{
        padding: 50px 0;
    }
	.form_row{
		flex-wrap: wrap;
	}
	.form_col{
		width: 100%;
	}
	.course-banner .main-title{
		width: 100%;
	}
}
@media screen and (max-width: 600px){
    .navbar-collapse{
        width: 50%;
    }
    .banner-outer h1{
        font-size: calc(100% + 8vw);
        line-height: normal;
        letter-spacing: normal;
        font-weight: 800;
    }
    .logo_animation img{
        width: 150px;
        height: 150px;
    }
        .course_section h2 {
        font-size: 90px;
        line-height: 80px;
        margin-top: 30px;
        letter-spacing: normal;
    }
    .title-row .title,
    .goals_future_section .title, .musical_journey_section .title,
    .title{
        font-size: calc(100% + 8vw);
        line-height: normal;
        letter-spacing: normal;
    }
    .title-row .content,
    .content,
    .banner-outer p{
        font-size: 18px;
        line-height: 30px;
    }
    .goals_icon{
        width: 100px;
        height: 100px;
    }
    .music_journey_row.book_musical_row{
        flex-wrap: wrap;
        gap: 15px;
    }
    .music-content, .music_animation{
        width: 100%;
    }
    .accordion-header button{
        font-size: 22px;
        letter-spacing: normal;
    }
    .footer_menu, .footer_menu ul{
        flex-wrap: wrap;
        gap: 10px;
    }
    .footer_menu ul li a{
        font-size: 17px;
    }
    .main-title{
        font-size: 35px;
        line-height: 35px;
    }
    .music_journey_row.all_course_row{
        flex-wrap: wrap;
    }
    .music_animation.music_animation,
    .about_us_section .all_course_row .music_animation {
        height: 300px;
    }
    .music_animation::after {
        background-size: contain;
    }
    .all_course_row:nth-child(odd){
        flex-direction: unset;
    }
    .music_journey_row {
        flex-direction: unset;
        gap: 15px;
    }
    .about_us_section .music_animation,
    .about_us_section .music-content{
        width: 100%;
    }
    .about_us_section .all_course_row{
        flex-direction: column-reverse !important;
    }
    .about_us_section .music-content h3, .about_us_section .music_animation h3{
        margin-bottom: 0;
    }
    .music_journey_row.all_course_row .music-content, .music_journey_row.all_course_row .music_animation{
        padding: 15px;
    }
    .music_journey_row.all_course_row .music_animation .custom-btn{
        top: 75%;
    }
    .form_outer p{
        flex-wrap: wrap;
        gap: 0;
    }
    .form_outer label{
        margin-bottom: 10px;
    }
    .working_days{
        flex-wrap: wrap;
        gap: 15px;
    }
	.goals_count{
		width: 50%;
	}
	    .goals_icon {
        width: 60px;
        height: 60px;
    }
	.goals_count h3{
		font-size: 30px;
	}
	.goals_count p{
		font-size: 18px;
	}
	.musical_journey_section.courseSection_outer {
   	 padding-bottom: 0;
	}
}