.headerGeniusballLogo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10%;
    visibility: hidden;
}

.headerS4ULogo {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 6.5%;
    visibility: hidden;
}

.headerTeamName {
    position: absolute;
    top: 0px;
    left: 38%;
    font-weight: bold;
    font-size: 35px;
    color: #17375E;
    visibility: hidden;
}

.headerTeamLogo {
    position: absolute;
    top: 0px;
    left: 28%;
    width: 6%;
    visibility: hidden;
}

.headerReportName {
    position: absolute;
    top: 95px;
    left: 20%;
    font-weight: bold;
    font-size: 45px;
    color: #17375E;
    visibility: hidden;
}

@media print {
    body {
        background-color: white;
    }
    body * {
        visibility: hidden;
    }
    .printable-content, .printable-content * {
        visibility: visible;
    }

    .printable-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    .headerTeamName {
        visibility: visible;
    }
    .headerGeniusballLogo {
        visibility: visible;
    }
    .headerS4ULogo {
        visibility: visible;
    }
    .headerTeamLogo {
        visibility: visible;
    }
    .headerReportName {
        visibility: visible;
    }
    .tdaPrintable, .tdaPrintable * {
        visibility: visible;
    }
    .tdaPrintable {
        position: absolute;
        left: 0;
        top: 200px;
        width: 100%;
        height: auto;
    }
}
