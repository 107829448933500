.team-field-container {
    background-color: #222;
    background-image: url("http://wallpapersafari.com/download/qxHlTK/");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
}

.containerr {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    /* background-image: url("http://wallpapersafari.com/download/qxHlTK/");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; */
}
.teamHeading h1 {
    position: absolute;
    text-align: center;
    color: #fff;
    z-index: 99999;
    text-align: center;
    padding-top: 25px;
    font-weight: 800;
    font-family: "Pacifico", cursive;
    color: #f2a11c;
    text-transform: uppercase;
    margin-left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.containerr .content {
    /* background: rgba(0, 0, 255, 0.25); */
    flex-grow: 1;
    display: flex;
}

.table-container {
    background: transparent;
    flex-basis: auto;
    flex-grow: 1;
}

.field-container {
    perspective: 600px;
    /* background: rgba(255, 255, 255, 0.25); */
    width: 100%;
}
.field-container .f-field {
    width: 100%;
    height: 100%;
    background: url(https://image.ibb.co/fOXX8w/field.png);
    background-size: 100% 100%;
    transform-style: preserve-3d;
    transform-origin: center bottom;
    transform: translatey(-10%) rotatex(35deg) scale(1);
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 24% 50% 24%;
    color: #444;
    box-sizing: border-box;
    padding: 4% 2%;
    margin: 0 auto;
    -webkit-transform: translatey(-10%) rotatex(35deg) scale(1);
    -moz-transform: translatey(-10%) rotatex(35deg) scale(1);
    -ms-transform: translatey(-10%) rotatex(35deg) scale(1);
    -o-transform: translatey(-10%) rotatex(35deg) scale(1);
}
.field-container .f-field > div {
    position: relative;
    /* box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5), inset 0 0 32px 0 rgba(255, 255, 255, 0.75);
    background: rgba(0, 0, 255, 0.25); */
    color: #fff;
    border-radius: 8px;
    padding: 20px;
    font-size: 150%;
    /* text-align: center; */
    transition: all 0.1s ease-in-out;
    transform-style: preserve-3d;
    perspective: 600px;
    transform-origin: center bottom;
}
.field-container .f-field > div .pl {
    position: relative;
    width: 110px;
    height: 110px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/bm-neuer.jpg);
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 18%;
    left: 50%;
    transform-style: preserve-3d;
    color: white;
    font-size: 16px;
    font-family: arial;
    /* line-height: 40px; */
    font-weight: 700;
    transition: all 0.2s ease-out;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transform: translateZ(35px) rotateX(-25deg) translateX(-50%);
    -webkit-transform: translateZ(35px) rotateX(-25deg) translateX(-50%);
    -moz-transform: translateZ(35px) rotateX(-25deg) translateX(-50%);
    -ms-transform: translateZ(35px) rotateX(-25deg) translateX(-50%);
    -o-transform: translateZ(35px) rotateX(-25deg) translateX(-50%);
}

.field-container .f-field > div .pl:hover {
    border: 3px solid whitesmoke;
    z-index: 999999;
}

.field-container .f-field > div .pl .name {
    position: relative;
    top: 90%;
    /* left: 50%; */
    transform: translatex(-50%);
    color: #fff;
    font-size: 17px;
    /* text-shadow: 1px 0 0 black, 1px 1px 0 black, -1px 0 0 black, 1px -1px 0 black, -1px 1px 0 black, -1px -1px 0 black; */
    transition: all 0.2s ease-out;
    opacity: 0;
}
.field-container .f-field > div .pl .name {
    text-decoration: none !important;
    background-color: rgba(0, 0, 0, 0.808);
    padding: 3px 12px;
    text-align: center;
    border-radius: 25px;
    color: #fff;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}
.field-container .f-field > div .pl {
    text-decoration: none !important;
}
.field-container .f-field > div .pl:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/bm-neuer.jpg); */
    background-size: cover;
    background-position: center center;
    content: "";
    transition: all 0.2s ease-out;
    transform: translate3d(0, 0, -1px);
    transform-origin: center bottom;
    transform-style: preserve-3d;
}
.field-container .f-field > div.gk .pl {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/bm-neuer.jpg);
    color: #fff;
}
.field-container .f-field > div.p2 .pl {
    left: 33.33333%;
}
.field-container .f-field > div.p2 .pl:last-child {
    left: 66.66667%;
}
.field-container .f-field > div.p3 .pl:first-child {
    left: 25%;
}
.field-container .f-field > div.p3 .pl:last-child {
    left: 75%;
}
.field-container .f-field > div:hover,
.field-container .f-field > div.active {
    transform: translatez(20px);

    /* box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 0, 0.25); */
    cursor: pointer;
}
/* .field-container .f-field > div:hover .pl, .field-container .f-field > div.active .pl {
    transform: translatey(0%) rotatex(-25deg) scale(1);
} */
.field-container .f-field > div .pl .name,
.field-container .f-field > div.active .pl .name {
    opacity: 1;
}
.field-container .f-field > div:hover .pl:after,
.field-container .f-field > div.active .pl:after {
    transform: translate3d(0%, 30%, -50px) scaley(0.75);
    opacity: 0;
}
.field-container .f-field .lf {
    grid-column: 1;
}
.field-container .f-field .cf {
    grid-column: 2;
    grid-row: 1;
}
.field-container .f-field .rf {
    grid-column: 3;
}
.field-container .f-field .lw {
    grid-column: 1;
}
.field-container .f-field .am {
    grid-column: 2;
    grid-row: 2;
}
.field-container .f-field .rw {
    grid-column: 3;
}
.field-container .f-field .lm {
    grid-column: 1;
}
.field-container .f-field .cm {
    grid-column: 2;
    grid-row: 3;
}
.field-container .f-field .rm {
    grid-column: 3;
}
.field-container .f-field .lmd {
    grid-column: 1;
}
.field-container .f-field .dm {
    grid-column: 2;
    grid-row: 4;
}
.field-container .f-field .rmd {
    grid-column: 3;
}
.field-container .f-field .ld {
    grid-column: 1;
    grid-row: 5/7;
}
.field-container .f-field .cd {
    grid-column: 2;
    grid-row: 5;
}
.field-container .f-field .rd {
    grid-column: 3;
    grid-row: 5/7;
}
.field-container .f-field .gk {
    grid-column: 2/2;
    grid-row: 6;
}
@media only screen and (max-width: 980px) {
    .field-container .f-field > div .pl {
        width: 75px;
        height: 75px;
    }

    .field-container .f-field > div .pl .name {
        font-size: 12px;
        padding: 2px 0px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .field-container .f-field > div .pl {
        width: 60px;
        height: 60px;
    }
    .field-container .f-field {
        transform: translatey(-20%) rotatex(35deg) scale(1);
        -webkit-transform: translatey(-20%) rotatex(35deg) scale(1);
        -moz-transform: translatey(-20%) rotatex(35deg) scale(1);
        -ms-transform: translatey(-20%) rotatex(35deg) scale(1);
        -o-transform: translatey(-20%) rotatex(35deg) scale(1);
    }
}
