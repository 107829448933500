.tableTitle {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #17375E;
    visibility: hidden;
}

.headerGeniusballLogo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10%;
    visibility: hidden;
}

.headerS4ULogo {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 6.5%;
    visibility: hidden;
}

.headerTeamName {
    position: absolute;
    top: 15px;
    left: 38%;
    font-weight: bold;
    font-size: 35px;
    color: #17375E;
    visibility: hidden;
}

.headerTeamLogo {
    position: absolute;
    top: 0px;
    left: 28%;
    width: 6%;
    visibility: hidden;
}

.headerReportName {
    position: absolute;
    top: 95px;
    left: 32%;
    font-weight: bold;
    font-size: 45px;
    color: #17375E;
    visibility: hidden;
}

@media print {
    body {
        background-color: white;
    }
    body * {
        visibility: hidden;
    }
    .printable-content, .printable-content * {
        visibility: visible;
    }

    .printable-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    .tableTitle {
        visibility: visible;
    }
    .headerTeamName {
        visibility: visible;
    }
    .headerGeniusballLogo {
        visibility: visible;
    }
    .headerS4ULogo {
        visibility: visible;
    }
    .headerTeamLogo {
        visibility: visible;
    }
    .headerReportName {
        visibility: visible;
    }
    .fcPrintable, .fcPrintable * {
        visibility: visible;
    }
    .fcPrintable {
        position: absolute;
        left: 0;
        top: 150px;
        width: 100%;
    }
    .fltPrintable, .fltPrintable * {
        visibility: visible;
    }
    .fltPrintable {
        position: absolute;
        left: 0;
        top: 485px;
        width: 100%;
    }
    .fvoPrintable, .fvoPrintable * {
        visibility: visible;
    }
    .fvoPrintable {
        position: absolute;
        left: 0;
        top: 1600px;
        width: 100%;
    }
    .fgbgPrintable, .fgbgPrintable * {
        visibility: visible;
    }
    .fgbgPrintable {
        position: absolute;
        left: 0;
        top: 3150px;
        width: 100%;
    }
    p.formationTitleName {
        font-size: 10px;
    }
    p.formationResult {
        font-size: 10px;
    }
}
