@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css';

.card-container.card {
    max-width: 350px !important;
    padding: 30px 30px;
}

body > iframe {
    display: none;
}

.MuiListItemButton-root.Mui-selected {
    color: #ffffff;
    background-color: darkblue !important;
    border-radius: 5px;
}
.css-qoi9-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 4px 0 !important;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.detection {
    display: flex;
    top: 0;
    justify-content: space-evenly;
    position: absolute;
    width: 97%;
    height: 97%;
    flex-wrap: wrap;
    pointer-events: auto;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.player-shared-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Optional: black background */
}

.react-shared-player > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This makes the video stretch and cover the whole area */
}

.tableline {
    border-bottom: 1px solid #484747;
}

.tableline td {
    padding: 0 12px;
    font-size: 12px;
}

.tableline:hover td {
    background: #484747;
}

.tableline.selected * {
    background: #ab6a6a;
}

.trashcell:hover {
    cursor: pointer;
}

.tablebody {
    overflow-x: auto;

    border-style: solid;

    border-width: 20;

    border-spacing: 10;

    border-collapse: separate;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.parent-container {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiDropzoneArea-textContainer {
    display: none;
}
.MuiDropzonePreviewList-removeButton {
    bottom: 40px;
    right: -200px !important;
    top: auto !important;
}

.MuiTableCell-root {
    padding: 0px !important;
    font-size: 0.7rem !important;
}
.MuiTablePagination-root p {
    margin: 0px !important;
}

.card {
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

div.team-theme-color {
    width: 50px;
    height: 30px;
    border-radius: 6px;
    margin: auto;
}

.css-19kzrtu {
    padding: 0px !important;
}

.MuiButtonBase-root-MuiMenuItem-root {
    font-size: 0.8rem !important;
}

.css-sytnxd-MuiButtonBase-root-MuiIconButton-root {
    padding: 0 !important;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}
.player-select-checkbox .css-1e6ud36-MuiButtonBase-root-MuiCheckbox-root {
    padding: 3px;
}
.Mui-expanded {
    margin: 2px 0 !important;
}

.MuiCheckbox-root {
    padding: 1px !important;
}

.slipmain {
    transform-origin: 0 50%;
    transform: perspective(1920px) rotate3d(0, 1, 0, 9deg);
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    background-color: #111;
    position: absolute;
    transition: transform 1s ease; /* Add transition property */
}

/* Define keyframes for animation */
@keyframes slideIn {
    0% {
        transform: perspective(1920px) rotate3d(0, 1, 0, 9deg);
    }
    100% {
        transform: perspective(1920px) rotate3d(0, 1, 0, 0deg);
    }
}

.slipmain-reverse {
    transform-origin: 0 50%;
    transform: perspective(1920px) rotate3d(0, 1, 0, 0deg);
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    background-color: #111;
    position: absolute;
    transition: transform 1s ease; /* Add transition property */
}

/* Define keyframes for animation */
@keyframes slideIn {
    0% {
        transform: perspective(1920px) rotate3d(0, 1, 0, 0deg);
    }
    100% {
        transform: perspective(1920px) rotate3d(0, 1, 0, 9deg);
    }
}

.video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-player.rotated {
    transform: rotate(90deg);
    transform-origin: center center;
    width: 100vh; /* 100% of the viewport height */
    height: 100vw; /* 100% of the viewport width */
}
