@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

/*Custom Css Changes*/
:root {
    --fonts: 'Exo 2', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
button,
input,
textarea,
select {
    outline: unset !important;
}

ul {
    list-style: none;
}

a {
    text-decoration: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

img {
    max-width: 100%;
}

.row {
    margin: 0 !important;
}
.container {
    max-width: 1320px;
    width: 100%;
}
section {
    overflow: hidden;
}

.bodyBackground {
    background-image: url(../../assets/images/background-img.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.bodyBackground::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000091;
    z-index: -1;
}

/* Header Start */
/* header */
header {
    position: relative;
    z-index: 99;
}
header ul {
    display: flex;
    gap: 50px;
    font-size: 20px;
    color: #fff;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    /* border-bottom: 0.1px solid #f1f1f1; */
    font-weight: 500;
    position: relative;
    z-index: 1;
}
header ul::after {
    content: '';
    position: absolute;
    background-color: #2c4b5f;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
header ul li a {
    color: #fff;
    font-family: var(--fonts);
    font-size: 17px;
    line-height: normal;
    border: 1px solid #c3c3c3;
    padding: 5px 25px 8px;
    border-radius: 50px;
    display: flex;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
}
header ul li a:hover,
header ul li.active a {
    background-color: #fff;
    color: #2c4b5e;
}

.progress {
    width: 80px;
    height: 80px;
    background: none;
    position: relative;
}

.progress::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
}

.progress > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}

.progress .progress-value {
    position: absolute;
    top: 0;
    left: 0;
}

.progress-bar {
    border-color: #4fcefe;
}

/* Skill Col */
.skill-info {
    width: 110px;
    height: 110px;
    /* border: 1px solid #c3c3c3; */
    border-radius: 100%;
    background-color: #37b8f2;
    padding: 13px;
    box-shadow: 5px 6px 20px #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(0, 0, 0, 1) 0px 0px 36px -24px;
}

.skill-info-mini {
    width: 60px;
    height: 60px;
    /* border: 1px solid #c3c3c3; */
    border-radius: 100%;
    background-color: #37b8f2;
    padding: 13px;
    box-shadow: 5px 6px 20px #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(0, 0, 0, 1) 0px 0px 36px -24px;
}

.skill-info img {
    width: 75px;
    filter: drop-shadow(5px 5px 4px #00000094);
}

.goals-number {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #00000094;
    padding: 15px;
    border-radius: 10px;
    justify-content: center;
}
.goals-number h3 {
    width: 100%;
    font-size: 17px;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
}
.player-info-row {
    margin: 50px 0 30px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #c3c3c3;
}
.player-info-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 20%;
    align-items: center;
}
.player-profile-img {
    width: 450px;
    height: 430px;
}
.player-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.game-details-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.game-col {
    position: relative;
    width: calc(100% / 3 - 20px);
    background: linear-gradient(114deg, rgba(21, 44, 54, 1) 0%, rgba(48, 87, 110, 1) 100%);
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.game-col::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(14deg, rgba(104, 122, 132, 1) 0%, rgba(104, 122, 132, 1) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.game-col h3 {
    font-size: 23px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-img {
    width: 100px;
    height: 100px;
    background-color: #1c303b;
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid #c3c3c3;
    margin: 0 auto;
}
.user-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 5px;
    filter: drop-shadow(-150px 0px 0px #95cef3);
    position: relative;
    left: 150px;
}
.rating-star {
    font-size: 20px;
    color: #95cef3;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.game-col h5 {
    font-size: 25px;
    color: #eeeeee;
}

.jerssy_numberOuter {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
.jerssy_col {
    width: 70px;
    height: 70px;
    /* background-image: url(../img/bg-numbers01.png); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-family: 'Exo 2';
    font-size: 18px;
    background-color: #0f2936;
    border-radius: 100%;
    border: 5px solid #37b8f2;
    border-left: unset;
    border-bottom: unset;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
}
.jerssy_col:nth-child(2) {
    /* background-image: url(../img/bg-numbers02.png); */
    /* color: #0564d2; */
    background-color: #0f2936;
}
.jerssy_numberOuter h3 {
    color: #f1f1f1;
    font-size: 15px;
    width: 100%;
    text-align: center;
}
.player-detail {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
}
.player-detail .team-logo {
    padding: 0;
    border: unset;
}
.team-info {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: calc(100% - 70px);
    margin-left: auto;
}
.team-info span {
    font-size: 15px;
    padding: 8px 15px;
    background-color: #0f2a36;
    color: #95cef3;
    font-weight: 500;
    z-index: 0;
    border-radius: 10px;
    border: 1px solid;
}
.player-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 5px);
}
.player-col img {
    position: relative;
    z-index: 1;
    border: 2px solid #f1f1f1;
    border-radius: 50px;
    width: 25%;
    padding: 5px;
}
.player-col span {
    font-size: 17px;
    padding: 60px 10px 10px;
    background-color: #0f2a36;
    color: #95cef3;
    font-weight: 500;
    position: relative;
    top: -50px;
    z-index: 0;
    border-radius: 10px;
}

.skill-infoSec {
    border: 1px solid #f1f1f1;
    padding: 8px;
    /* background-color: #18303b; */
    position: relative;
    z-index: 1;
    flex-direction: column;
}
.skill-infoSec::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    background-color: #3783aa;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
}
.skill-infoSec::before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 80%;
    height: 85%;
    border-radius: 100%;
    background-color: #3782a9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #f1f1f1;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
}
.skill-infoSec img {
    width: 65px;
    filter: unset;
    height: 70px;
    top: unset;
    z-index: 1;
}
.skill-infoSec span {
    color: #3794c1 !important;
    z-index: 1;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    font-family: 'Exo 2';
}

.ts-info {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 25px;
}
.ts-col {
    text-align: center;
}
.ts-col span {
    color: #f1f1f1;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    display: inline-flex;
}
.ts-col img {
    width: 60px;
    height: 60px;
    background-color: #436f87;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.js-col .jerssy_col {
    /* background-image: url(../img/bg-numbers03.png); */
    width: 70px;
    height: 70px;
}
.js-col:nth-child(2) .jerssy_col {
    /* background-image: url(../img/bg-numbers04.png); */
}
.js-col {
    width: 90px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
}
.js-col h3 {
    line-height: 22px;
}
.disabled-link {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}
/* @media screen and (max-width: 1500px) { */
body.bodyBackground {
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    /* padding-bottom: 30px; */
}
/* } */

.player-action-outer {
    width: 100%;
    height: 100%;
    background: linear-gradient(25deg, #061c2a 0%, #0f384a 100%);
    padding-bottom: 50px;
    min-height: 100vh;
}
.player-action-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}
.player-action-heading span {
    width: 55px;
}
.player-action-heading h5 {
    font-size: 38px;
    color: #fff;
    font-family: var(--fonts);
    font-weight: 600;
}
.player-action-details {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 50px;
}
.action-img {
    width: 120px;
    height: 120px;
    background-color: #0f384a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: relative;
    z-index: 1;
    box-shadow: inset rgb(0 0 0 / 69%) 10px 10px 10px 0px, inset rgb(60 70 85) 0px 0px 0px 0px, inset rgba(0, 0, 0, 1) 0px 0px 0px -24px;
    /* overflow: hidden; */
    transform: rotate(-45deg);
}
.action-img img {
    width: 60px;
    transform: rotate(45deg);
}
.action-img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border: 8px solid #154c60;
    z-index: -1;
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;
    border-bottom: unset;
}
.action-img::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border: 8px solid #f1f1f1;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    z-index: -1;
    border-top: unset;
}
.action-col {
    width: calc(100% / 4 - 37.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.action-col h3 {
    color: #fff;
    font-size: 25px;
    font-family: var(--fonts);
}
.act-info {
    display: flex;
    width: 50%;
    align-items: center;
    background-color: #0f384a;
    color: #fff;
    padding: 5px 20px;
    font-size: 25px;
    font-family: var(--fonts);
    box-shadow: 5px 5px 15px #0c0c0c73;
    border-radius: 15px;
    margin-top: 10px;
    justify-content: center;
    gap: 50px;
    position: relative;
}
.act-info::after {
    content: '';
    width: 80px;
    height: 3px;
    background-color: #fff;
    top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
}
.action-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 50px;
}
.analitys_col {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.analitys_col h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 23px;
    font-family: var(--fonts);
    color: #fff;
}
.analitys_col img {
    height: 200px;
    object-fit: contain;
    width: 100%;
}
.actionSec-row {
    margin-bottom: 0;
}
.anilytic-row {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.anilytic-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.anilytic-col span {
    width: 80px;
    height: 80px;
    background-color: #fff;
    display: flex;
    border-radius: 100%;
    position: relative;
    z-index: 1;
}
.anilytic-col span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: #082231;
    border-radius: 100%;
}
.anilytic-col h2 {
    font-size: 17px;
    color: #fff;
}

.playerSec-profile-img {
    position: relative;
    z-index: 2;
    width: 350px;
    /* overflow: hidden; */
}
.playerSec-profile-img img {
    z-index: 1;
    position: relative;
}
.playerSec-profile-img::after {
    content: '';
    position: absolute;
    top: 0;
    width: 500px;
    height: 500px;
    left: 50%;
    background-image: url(../../assets/images/profile_background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    z-index: -1;
    transform: translateX(-50%);
    box-shadow: inset rgb(10 41 57) 0px 0px 200px 0, inset rgb(10 40 55) 0px 0px 200px 0px, inset rgb(11 42 58) 0px 0px 200px 0;
}
.playerSec-profile-img::before {
    content: '';
    width: 80%;
    height: 50%;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    bottom: -5px;
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(30px);
    opacity: 0.8;
}
.player-infoSec-row {
    border: unset;
}
.player-infoSec-row .player-info-col {
    width: 25%;
}
.player-infoSec-row .player-info-col:nth-child(3) {
    text-align: right;
}
.player-infoSec-row .player-info-col h3 {
    font-size: 20px;
    color: #fff;
    font-family: var(--fonts);
    padding-bottom: 20px;
    border-bottom: 2px solid #c3c3c3;
    width: 100%;
    text-transform: uppercase;
}
.info-details {
    width: 100%;
}
.info-details h6 {
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
}
.info-details p {
    display: flex;
    align-items: center;
    color: #fff;
    gap: 13px;
    margin-bottom: 15px;
}

.info-details p .progress-bar {
    height: 10px;
    width: 70%;
    display: flex;
    background-color: #fff;
    border-radius: 50px;
}
.player-infoSec-row .player-info-col:nth-child(3) .info-details p {
    flex-direction: row-reverse;
}
.score-number {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    font-family: var(--fonts);
    margin: 12px 0;
    display: inline-flex;
}
.action-row-data {
    flex-wrap: wrap;
    border: unset;
    padding: 0;
    margin-bottom: 0;
}
.action-row-data .analitys_col {
    width: calc(100% / 3 - 15px);
}
.action-row-data .analitys_col img {
    height: 300px;
    width: 100%;
    object-position: 100% 100%;
    object-fit: unset;
}
.player-details-outerSec {
    background: linear-gradient(25deg, #00070e 0%, #000711 100%);
}
.gameData {
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1;
    position: relative;
}
.team-logo {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    border-bottom: 0.5px solid #c3c3c3;
    padding-bottom: 15px;
}
.team-logo:nth-last-child(1) {
    border-bottom: unset;
    /* padding-bottom: 0; */
}
.team-logo:nth-child(1):after {
    content: 'VS';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    font-weight: 500;
    color: #f3f3f3;
    font-family: 'Exo 2';
    background: #1c303b;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
}
.team-logo img {
    width: 60px !important;
    height: 60px;
    object-fit: contain;
    border: 5px solid #95cef3;
    padding: 5px;
    border-left: unset;
    border-radius: 100%;
    border-bottom: unset;
    background-color: #fff;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
}
.team-name,
.team-score {
    font-size: 18px;
    font-weight: 500;
    color: #f3f3f3;
    font-family: 'Exo 2';
}
.team-score {
    font-size: 20px;
    margin-left: auto;
}
.stats-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    border-top: 0.5px solid #c3c3c3;
    padding-top: 15px;
}
.stats-row span,
.stats-row a {
    font-size: 17px;
    font-family: 'Exo 2';
    color: #fff;
    display: flex;
    gap: 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.stats-row span:hover,
.stats-row a:hover {
    color: #95cef3;
}
/* Advance Stats Page */
.player-row-outer {
    display: flex;
    /* gap: 30px; */
    gap: 20px;
    flex-wrap: wrap;
    margin: 50px 0;
    align-items: flex-start;
}
.player-profile {
    position: sticky;
    width: 25%;
    background: linear-gradient(114deg, rgba(21, 44, 54, 1) 0%, rgba(48, 87, 110, 1) 100%);
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    z-index: 1;
    top: 100px;
    bottom: 0;
}
.player-profile::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(14deg, rgba(104, 122, 132, 1) 0%, rgba(104, 122, 132, 1) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.player-info {
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 1;
}
.profile-heading {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-heading h3 {
    font-size: 18px;
    font-family: var(--fonts);
    font-weight: 600;
    color: #154c60;
}
.profile-heading a {
    border: 1px solid #2c4b5f;
    padding: 5px 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #fff;
    background-color: #2c4b5f;
    font-family: var(--fonts);
    font-weight: 500;
    transition: all 0.5s ease-in-out;
    font-size: 14px;
}
.profile-heading a:hover {
    background-color: #fff;
    color: #154c60;
}
.player-profile h5 {
    font-size: 25px;
    font-family: var(--fonts);
    color: #fff;
    font-weight: 600;
}
.player-img {
    width: 130px;
    height: 130px;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
}
.player-img img {
    object-fit: cover;
    object-position: 0 0px;
}
.player-details {
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.info-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #c3c3c3;
    padding: 10px;
}
.info-col:nth-last-child(1) {
    padding-bottom: 0;
}
.info-col span {
    color: #fff;
    font-size: 18px;
    font-family: var(--fonts);
    font-weight: 500;
}

.faul-row {
    display: flex;
    gap: 15px;
    margin: 15px 0 0;
}
.faul-col {
    width: 42px;
    position: relative;
}
.faul-col img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.faul-col span {
    position: absolute;
    top: 8px;
    color: #fff;
    left: 40%;
    font-family: var(--fonts);
    font-weight: 600;
    font-size: 20px;
    transform: translateX(-50%);
}

.filter-col {
    width: 100%;
    border: 1px solid #c3c3c3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 10px;
}
.advance-filter-option {
    /* width: calc(100% - 30% - 30px); */
    width: 100%;
}

.match-period {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.match-period h3 {
    font-size: 18px;
    color: #fff;
    font-family: var(--fonts);
    font-weight: 500;
    margin: 0;
}

.box-col {
    width: 50%;
}
.multi-col {
    display: flex;
    width: 100%;
}
.option-col {
    width: 100%;
}
/* input[type='checkbox'],
input[type='radio'] {
    display: none;
} */
.input-hide-checkbox {
    display: none;
}
.check-col label {
    border: 1px solid #c3c3c3;
    display: inline-flex;
    width: 100%;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-family: var(--fonts);
    padding: 8px 15px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.first-col.check-col:nth-child(1) label {
    border-bottom: unset;
}
.box-col:nth-child(odd) .first-col.check-col label {
    border-bottom: unset;
    border-left: unset;
}
.box-col:nth-child(even) .check-col .option-col:nth-child(2) label {
    border-right: unset;
    border-left: unset;
}
.box-col:nth-child(odd) .check-col .option-col:nth-child(2) label {
    border-right: unset;
    border-left: unset;
}
.box-col:nth-child(odd) .check-col .option-col:nth-child(1) label {
    border-left: unset;
}

.check-col input[type='checkbox']:checked + label,
.check-col input[type='radio']:checked + label,
.check-col input[type='radio']:checked + .textpie {
    background-color: #2c4b5f;
    color: #fff;
    /* border-color: #007bff; */
}
.check-col input[type='checkbox']:checked + label.textpie {
    background-color: #2c4b5f;
}
.check-col input[type='checkbox']:checked + div.pieContainer {
    background-color: red;
}

.check-col label:hover {
    background-color: #2c4b5f;
    color: #f3f3f3;
}
.game-result {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 15px;
}
.ground-option {
    height: 180px;
    width: 100%;
    background: url(../../assets/images/ground-img.png) #c3c3c3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
}
/* .ground-option::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ffffff52;
} */
.result-option {
    width: 50%;
}
.result-option .match-period {
    padding: 0;
}
.ground-label {
    display: flex;
    width: 100%;
    height: 100%;
    border-right: 2px solid #fff;
    cursor: pointer;
    background-color: #ffffff66;
}
.ground-label:nth-last-child(1) {
    border-right: unset;
}
.ground-option input[type='checkbox']:checked + label.ground-label {
    background-color: transparent;
    border-right: 2px solid #fff;
}
.back-button a {
    font-size: 17px;
    font-family: var(--fonts);
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #f1f1f1;
    padding: 5px 15px;
    border-radius: 6px;
    width: fit-content;
    color: #2c4b5f !important;
    font-weight: 600;
    margin-left: auto;
    border: 1px solid #f1f1f1;
    transition: all 0.3s ease-in-out;
}
.back-button a:hover {
    background-color: #2c4b5f !important;
    color: #fff !important;
}
.back-button a span {
    color: #2c4b5f !important;
}
.back-button a:hover span {
    color: #fff !important;
}
.team-info-sec {
    margin: 30px 0;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 30px;
}
.team-info-sec span {
    width: calc(100% / 3 - 35px);
    border-radius: 8px;
    font-size: 18px;
    padding: 10px 15px 11px;
    font-family: var(--fonts);
    font-weight: 600;
}

.seemore-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Exo 2';
    width: auto;
    margin: 10px auto;
    background-color: #2c4b5f;
    padding: 4px 10px;
    border-radius: 50px;
    border: 1px solid #c3c3c3;
}
.seemore-btn span {
    color: #2c4b5f;
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background: #f1f1f1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 50px;
}

header ul li a i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 50px;
    margin-left: 10px;
    position: relative;
    top: 1px;
    margin-left: auto;
}
.selectSeasson {
    position: relative;
    width: 185px;
}
.dropdown-outer {
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    border: 1px solid #c3c3c3;
    top: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
    display: none;
    height: 0;
    transition: all 0.5s ease-in-out;
}
.dropdown-outer ul {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
}
.dropdown-outer ul li {
    color: #fff;
    font-family: var(--fonts);
    font-size: 16px;
    line-height: normal;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    padding: 7px 10px 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #c3c3c3;
}
.dropdown-outer ul li:nth-last-child(1) {
    border-bottom: unset;
}
.dropdown-outer ul li.active,
.dropdown-outer ul li:hover {
    background-color: #fff;
    color: #2c4b5f;
}
.dropdown-outer.menu-open {
    height: auto;
    display: block;
}
.player-profile-img {
    position: relative;
}
.player-profile-img h3 {
    color: #fff;
    font-family: var(--fonts);
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    font-size: 30px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #3788b0;
    padding-bottom: 5px;
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, inset rgba(0, 0, 0, 1) 0px 0px 36px -24px;
    filter: drop-shadow(5px 5px 1px #00000094);
}

.back-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.back-button h3 {
    font-size: 25px;
    font-family: var(--fonts);
    color: #fff;
    font-weight: 500;
}
.home-town-select {
    z-index: 1;
    gap: 16px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.home-town-select .option-col {
    width: calc(100% / 2 - 8px);
}
.home-won-select .option-col {
    width: calc(100% / 3 - 11px);
}
.home-town-select.check-col label {
    border-radius: 5px;
    flex-direction: column;
    background-color: #ffffff66;
}
.home-town-select.check-col label span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #2c4b5f;
    background-color: #fff;
    margin-bottom: 5px;
    font-size: 20px;
    position: relative;
}
.home-town-select.check-col label span.lose::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ff0000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-30deg);
}
.home-town-select.check-col label span {
    overflow: hidden;
}
.home-town-select.check-col label span img {
    width: 90%;
    position: relative;
    left: 45px;
    filter: drop-shadow(-45px 0px 0px #2c4b5f);
}
.check-col input[type='radio']:checked + label span {
    background-color: #fff;
    color: #2c4b5f;
}
.check-col input[type='radio']:checked + label span img {
    filter: drop-shadow(-45px 0px 0px #2c4b5f);
}

.pieContainer {
    height: 200px;
    position: relative;
    width: 200px;
    margin: 0 auto;
}

.pieBackground {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    /* background-color: #2c4b5f; */
    background-color: #f1f1f1;
}

.pie {
    transition: all 1s;
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    clip: rect(0px, 99px, 200px, 0px);
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
    transform: rotate(60deg);
    left: 0;
    z-index: 1;
    /* background-color: #ffffff66; */
    background-color: #7c909b;
}

.hold {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    clip: rect(0px, 200px, 200px, 101px);
    box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 5px 0px, inset rgb(60 70 85 / 47%) 0px 0px 5px 0px, inset rgba(0, 0, 0, 1) 0px 0px 5px -40px;
}

#pieSlice2 {
    transform: rotate(60deg);
}

#pieSlice3 {
    transform: rotate(120deg);
}

#pieSlice4 {
    transform: rotate(180deg);
}

#pieSlice5 {
    transform: rotate(240deg);
}

#pieSlice6 {
    transform: rotate(300deg);
}

.player-profile.profile-photo {
    padding: 0;
    width: 29.9%;
}
.player-photo {
    width: 100%;
    height: 200px;
    position: relative;
    /* z-index: 1; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
}
.player-photo::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 266%;
    z-index: -1;
    background-image: url(../../assets/images/background-img.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.player-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.player-minuties-row {
    padding: 0 15px 15px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.player-minutes {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}
.team-logo.ad-team-logo:nth-child(1):after {
    display: none;
}
.team-logo.ad-team-logo {
    width: 170px;
    flex-direction: column;
    padding: 0;
    border: unset;
}
.ad-minutes-detail {
    width: 100%;
}
.ad-minutes-detail {
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    overflow: hidden;
}
.ad-minutes-detail p {
    font-size: 17px;
    color: #fff !important;
    font-family: var(--fonts);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 15px 8px;
    border-bottom: 1px solid #f1f1f1;
}
.ad-minutes-detail p:nth-last-child(1) {
    border: unset;
}

.team-logo.ad-team-logo h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    font-family: var(--fonts);
    line-height: 25px;
}

.player-actionOuter .player-action-details {
    gap: 5px;
    margin: 0;
    z-index: 1;
    row-gap: 25px;
}
.player-action-details .action-col {
    cursor: pointer;
    width: calc(100% / 4 - 4px);
    gap: 0;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 25px;
}
.player-action-details .action-img {
    width: 65px;
    height: 65px;
}
.player-action-details .action-img img {
    width: 45px;
}
.player-action-details .action-img::before,
.player-action-details .action-img::after {
    border-width: 4px;
}
.player-action-details .action-col h3 {
    font-size: 13.5px;
    margin: 15px 0 5px;
    white-space: nowrap;
}
.player-action-details .action-col span {
    color: #fff;
    font-family: var(--fonts);
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}
.act-info {
    padding: 5px 15px;
    width: 100%;
    border-radius: 5px;
    font-size: 20px;
}

.player-profile.player-actionOuter {
    width: 42%;
}

.stats-data h5 {
    transform: rotate(45deg);
    font-size: 22px;
}

:root {
    --bg-01: #f34c68;
    --bg-02: #ee7e33;
    --bg-03: #428a11;
    --bg-04: #cb5212;
    --bg-05: #bb1c58;
    --bg-06: #7357c2;
    --bg-07: #607490;
    --bg-08: #99c07d;
    --bg-09: #588ca4;
    --bg-10: #e28f63;
}
.bg-01 {
    background-color: var(--bg-01);
}
.bg-02 {
    background-color: var(--bg-02);
}
.bg-03 {
    background-color: var(--bg-03);
}
.bg-04 {
    background-color: var(--bg-04);
}
.bg-05 {
    background-color: var(--bg-05);
}
.bg-06 {
    background-color: var(--bg-06);
}
.bg-07 {
    background-color: var(--bg-07);
}
.bg-08 {
    background-color: var(--bg-08);
}
.bg-09 {
    background-color: var(--bg-09);
}
.bg-10 {
    background-color: var(--bg-10);
}
.player-profile.pogressDataOuter {
    width: 56.4%;
}
.player-progress-outer {
    z-index: 1;
}
.player-pogress-col {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    z-index: 1;
}
.player-pogress-col:nth-last-child(1) {
    border: unset;
    padding: 0;
}

.bar-status-row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    /* margin: 15px 0 5px; */
    margin: 15px 0 10px;
    flex-wrap: wrap;
    min-height: 50px;
}
.bar-status-row span {
    display: flex;
    gap: 5px;
    color: #d4d3d3;
    font-family: var(--fonts);
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
    align-items: center;
}
.bar-status-row span b {
    width: 10px;
    height: 10px;
    display: inline-flex;
    border-radius: 50px;
}
.bar-line-outer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 1;
}
.bar-line-outer::after {
    content: '';
    width: 100%;
    height: 20px;
    background-color: #d4d3d3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.bar-line-outer span {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    padding: 0 15px;
    font-family: var(--fonts);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.bar-value-outer {
    display: flex;
    align-items: center;
    gap: 15px;
}
.bar-value {
    color: #fff;
    font-family: var(--fonts);
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
}
.skill-row-outer {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
}
.skills-graph {
    display: flex;
    flex-direction: column;
    width: 25%;
    gap: 20px;
}
.custom-column.player-profile {
    width: 100%;
    /* height: 300px; */
    overflow: hidden;
}
.soccer-player-image {
    width: 40%;
    height: 600px;
    position: relative;
    z-index: 1;
}
.soccer-player-image:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 200px;
    border-radius: 100%;
    border: 10px solid #d7d8d2;
    left: 50%;
    z-index: -1;
    filter: drop-shadow(30px -40px 10px black);
    transform: translateX(-50%);
    opacity: 0.5;
}
.soccer-player-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.skill-row-outerSec {
    display: flex;
    gap: 15px;
    width: 100%;
}
.skill-row-outerSec .custom-column.player-profile {
    width: calc(100% / 3);
}
/*text {
    fill: #fff !important;
}*/
.custom-column.player-profile .ground-option {
    height: 210px;
}
.custom-legend {
    left: 0 !important; /* Override the left position */
  }
@media screen and (max-width: 1310px) {
    .game-stats-row .player-profile {
        width: 48.2%;
    }
    .game-stats-row .player-profile.profile-photo {
        width: 50%;
    }
    .player-profile.player-actionOuter {
        width: 100%;
    }
}
@media screen and (max-width: 1200px) {
    .player-info-col {
        width: 25%;
    }
    .js-col .jerssy_col {
        width: 75px;
        height: 75px;
    }
    .jerssy_col {
        width: 75px;
        height: 75px;
    }
    .game-col {
        width: calc(100% / 3 - 10px);
    }
    .team-info-sec span {
        width: calc(100% / 3 - 10px);
    }
    .player-profile {
        width: 48.2%;
    }
    .player-profile.profile-photo {
        width: 50%;
    }
    .player-profile.player-actionOuter,
    .player-profile.pogressDataOuter {
        width: 100%;
    }
    .game-stats-row .player-profile {
        width: 48%;
    }
}
@media screen and (max-width: 1100px) {
    .game-col {
        padding: 15px;
    }
    .game-col h3 {
        font-size: 20px;
        font-weight: normal;
    }
    .game-stats-row .player-profile.profile-photo {
        width: 49.9%;
    }
}
@media screen and (max-width: 1000px) {
    .player-info-row {
        position: relative;
        z-index: 1;
    }
    .player-profile-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }
    .jerssy_col {
        width: 65px;
        height: 65px;
    }
    .faul-row {
        margin-top: 25px;
    }
    .js-col {
        gap: 12px;
    }
    .player-profile-img h3 {
        width: 50%;
        font-size: 25px;
    }
    .game-stats-row .player-profile,
    .game-stats-row .player-profile.profile-photo {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .jerssy_col {
        width: 57px;
        height: 57px;
    }
    .team-logo {
        padding-bottom: 15px;
        gap: 0px;
    }
    .team-logo:nth-last-child(1) {
        padding-bottom: 10px;
    }
    .team-logo img {
        width: 45px !important;
        height: 45px;
        border: 3px solid #95cef3;
        padding: 3px;
    }
    .team-name {
        width: 145px;
        font-size: 18px;
    }
    .team-score {
        font-size: 20px;
    }
    .team-logo:nth-child(1):after {
        top: 46%;
        transform: translate(-50%, -45%);
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
    .stats-row span,
    .stats-row a {
        font-size: 16px;
    }
    .player-profile-img h3 {
        font-size: 18px;
        padding-top: 5px;
        top: -20px;
    }
}

@media screen and (max-width: 850px) {
    .jerssy_col {
        width: 50px;
        height: 50px;
        font-size: 15px;
        border-width: 3px;
    }
    .game-col h3 {
        font-size: 15px;
    }
    .stats-row span,
    .stats-row a {
        font-size: 15px;
        gap: 5px;
    }
    .team-name {
        width: 130px;
        font-size: 15px;
    }

    .game-stats-row .player-profile {
        width: 100%;
    }
    .game-stats-row .player-profile.profile-photo {
        width: 100%;
    }
    .player-profile.player-actionOuter {
        width: 100%;
    }
    .game-stats-row .team-name {
        font-size: 18px !important;
        width: auto !important;
        margin-left: 20px;
    }
}

@media screen and (max-width: 783px) {
    .jerssy_numberOuter img {
        height: 60px;
    }
    header ul {
        gap: 15px;
        padding: 15px 0;
    }
    header ul li a {
        padding: 5px 13px 8px;
        font-size: 14px;
    }
    .skill-info {
        width: 70px;
        height: 70px;
    }
    .player-info-col {
        z-index: 9;
        width: 30%;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
    }
    .player-info-row {
        margin: 35px 0 25px;
    }
    .jerssy_numberOuter {
        gap: 5px;
        margin-top: 0px;
    }
    .jerssy_col {
        width: 49px;
        height: 49px;
        font-size: 15px;
    }

    .player-detail {
        margin-top: 15px;
    }
    .player-col {
        width: 40%;
    }
    .player-col span {
        font-size: 12px;
        padding: 15px 5px 5px;
        top: -5px;
    }
    .jerssy_numberOuter h3 {
        font-size: 13px;
    }
    .ts-col img {
        width: 35px;
        height: 35px;
        padding: 5px;
    }
    .ts-col span {
        font-size: 10px;
        margin-top: 0px;
    }
    .ts-info {
        gap: 5px;
        margin: 0;
    }
    .js-col .jerssy_col {
        width: 45px;
        height: 45px;
    }
    .js-col h3 {
        line-height: 15px;
    }
    .js-col .jerssy_col {
        font-size: 12px;
    }
    .js-col {
        width: auto;
    }
    .player-info-row {
        border-bottom: unset;
    }
    .game-col {
        width: 31.3vw;
        padding: 10px;
        border-radius: 8px;
        gap: 5px;
    }
    .game-col h3 {
        font-size: 10px;
    }
    .game-col::before {
        border-radius: 8px;
    }
    .user-img {
        width: 35px;
        height: 35px;
    }
    .rating-star {
        font-size: 10px;
        gap: 5px;
    }
    .game-col h5 {
        font-size: 10px;
    }
    .game-details-row {
        gap: 5px;
        row-gap: 10px;
    }
    .skill-infoSec img {
        width: 40px;
        height: 42px;
    }
    .skill-infoSec span {
        font-size: 15px;
    }
    body.bodyBackground {
        height: 100%;
    }
    .player-action-heading h5 {
        font-size: 25px;
    }
    .player-action-heading span {
        width: 30px;
    }
    .player-action-details {
        gap: 0;
        margin-top: 20px;
        row-gap: 20px;
    }
    .action-col {
        cursor: pointer;
        width: calc(100% / 4);
        gap: 8px;
    }
    .action-img {
        width: 70px;
        height: 70px;
    }
    .action-img::before {
        border-width: 5px;
    }
    .action-img::after {
        border-width: 5px;
    }
    .action-img img {
        width: 40px;
    }
    .action-col h3 {
        font-size: 10px;
    }
    .act-info {
        padding: 2px 15px;
        gap: 20px;
        font-size: 20px;
        width: 85%;
        border-radius: 10px;
    }
    .act-info::after {
        width: 40px;
        height: 2px;
    }
    .analitys_col h3 {
        font-size: 10px;
    }
    .action-row {
        gap: 5px;
        margin: 30px 0;
        padding-bottom: 30px;
    }
    .analitys_col img {
        height: auto;
    }
    .anilytic-col span {
        width: 40px;
        height: 40px;
    }
    .anilytic-col span::after {
        width: 25px;
        height: 25px;
    }
    .player-action-outer {
        height: 100%;
        padding: 0;
    }
    .anilytic-col h2 {
        font-size: 12px;
        text-align: center;
    }
    .player-infoSec-row .player-info-col {
        width: 30%;
    }
    /* .playerSec-profile-img {
    position: unset;
  } */
    .playerSec-profile-img::after {
        width: 250px;
        height: 250px;
        top: 20px;
    }
    .player-infoSec-row .player-info-col h3 {
        font-size: 10px;
        padding-bottom: 10px;
    }
    .info-details h6 {
        font-size: 10px;
    }
    .score-number {
        font-size: 15px;
        margin: 10px 0;
    }
    .info-details p {
        gap: 5px;
        margin-bottom: 10px;
        font-size: 10px;
    }
    .info-details p .progress-bar {
        height: 5px;
        width: 30%;
    }
    .action-row-data .analitys_col {
        width: calc(100% / 3 - 3.5px);
    }
    .action-row-data .analitys_col img {
        height: 80px;
    }
    .playerSec-profile-img::before {
        top: 100px;
        bottom: unset;
        width: 150px;
        height: 150px;
    }
    .player-details-outerSec {
        height: 100vh;
    }
    .gameData {
        margin-top: 10px;
        gap: 10px;
    }
    .team-logo {
        padding-bottom: 15px;
        gap: 0px;
    }
    .team-logo:nth-last-child(1) {
        padding-bottom: 10px;
    }
    .team-logo img {
        width: 35px !important;
        height: 35px;
        border: 3px solid #95cef3;
        padding: 3px;
    }
    .team-name {
        width: 90px;
        font-size: 12px;
    }
    .team-score {
        font-size: 18px;
    }
    .team-logo:nth-child(1):after {
        top: 46%;
        transform: translate(-50%, -45%);
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
    .stats-row {
        padding-top: 10px;
    }
    .stats-row span,
    .stats-row a {
        font-size: 10px;
        gap: 3px;
    }
    .seeMoreEffect {
        /* overflow: hidden; */
        width: 100%;
        /* overflow-x: auto; */
    }
    .seeMoreEffect .game-details-row {
        flex-wrap: wrap;
        /* width: max-content; */
        /* gap: 12px; */
    }

    .skill-info {
        width: 70px;
        height: 70px;
        margin-bottom: 30px;
        margin-top: 50px;
    }
    .selectSeasson {
        width: 142px !important;
    }

    .player-row-outer {
        margin: 30px 0;
        gap: 15px;
    }
    .player-profile,
    .advance-filter-option,
    .ground-option,
    .result-option,
    .player-profile.profile-photo {
        width: 100%;
    }
    .check-col label {
        padding: 5px 0;
        font-size: 12px;
    }
    .player-profile {
        margin-bottom: 20px;
    }

    .player-detail {
        gap: 5px;
    }
    .team-info {
        display: flex;
        gap: 5px;
        flex-direction: column;
        width: calc(100% - 30px);
        margin-left: auto;
    }
    .team-info span {
        font-size: 8px;
        padding: 5px;
        border-radius: 5px;
    }
    .player-detail .team-logo img {
        width: 25px !important;
        height: 25px;
        border: 2px solid #95cef3;
    }
    .js-col .jerssy_col {
        width: 60px;
        height: 60px;
        font-size: 15px;
    }
    .faul-col {
        text-align: center;
        width: 35px;
    }
    .faul-col span {
        top: 5px;
        left: 45%;
        font-size: 20px;
    }
    .team-info {
        margin-top: 20px;
        border-top: 1px solid #c3c3c3;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        flex-direction: row;
        gap: 8px;
    }
    .team-info span {
        font-size: 9px;
        width: calc(100% / 3 - 0px);
    }
    .ground-option {
        height: 180px;
    }
    .player-profile-img h3 {
        padding-top: 0px;
        top: -20px;
        background: unset;
        filter: unset;
        box-shadow: unset;
        font-size: 23px;
        width: 100%;
    }

    .player-profile-img {
        height: 250px;
    }

    .soccer-player-image {
        position: absolute;
        width: 60%;
        left: 50%;
        transform: translateX(-50%);
    }
    .skills-graph {
        width: 32%;
        position: relative;
        z-index: 2;
    }
    .skill-row-outerSec {
        flex-wrap: wrap;
    }
    .skill-row-outerSec .custom-column.player-profile {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .jerssy_numberOuter img {
        height: auto;
        width: 55% !important;
    }
    .jerssy_col {
        width: 30px;
        height: 30px;
        font-size: 10px;
        border-width: 2px;
    }
    .js-col .jerssy_col {
        width: 40px;
        height: 40px;
        font-size: 10px;
    }
    .game-col {
        width: 45.3vw;
    }
    .faul-col {
        width: 45px;
    }
    .faul-col span {
        top: 2px;
        font-size: 16px;
    }
    label.lableValue {
        font-size: 16px;
    }
    .team-info span {
        font-size: 12px;
        width: auto;
    }
    .player-action-details .action-col {
        cursor: pointer !important;
        width: calc(100% / 2 - 4px);
        padding-bottom: 15px;
    }
    .player-actionOuter .player-action-details {
        row-gap: 15px;
    }
    .bar-status-row {
        margin: 15px 0 10px;
        flex-wrap: wrap;
    }
    .skill-row-outer {
        flex-wrap: wrap;
    }
    .skills-graph {
        width: 100%;
    }
    .soccer-player-image {
        position: relative;
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .skill-info {
        margin-bottom: 15px;
        margin-top: 0;
    }
}
.player-detail .team-logo:after {
    display: none;
}

label.lableValue {
    width: auto;
    z-index: 3;
    position: absolute;
    border: unset;
    color: #fff;
    background-color: transparent !important;
    height: 55px;
    /* transform: rotate(-60deg); */
}
label.lableValue01 {
    top: 15px;
    right: 10%;
}
label.lableValue02 {
    right: 0px;
    top: 70px;
}
label.lableValue03 {
    top: 130px;
    right: 10%;
}
label.lableValue04 {
    top: 130px;
    left: 10%;
}
label.lableValue05 {
    left: 0px;
    top: 70px;
}
label.lableValue06 {
    top: 15px;
    left: 10%;
}

.check-col input[type='checkbox']:checked > .check-col label.lableValue01 {
    color: #2c4b5f;
}
@media screen and (max-width: 900px) {
    .modalVideoOuter,
    .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container .profileSection_tagvideo .MuiPaper-root.MuiPaper-elevation {
        min-width: 100% !important;
    }
    .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        min-width: 100% !important;
        max-height: 100% !important;
    }
    .css-ypiqx9-MuiDialogContent-root {
        padding: 0 !important;
    }
    .player-wrapper {
        height: 100vh !important;
    }
    .modalScoreHeading {
        left: 10px !important;
        right: 10px !important;
        top: 10px !important;
        padding: 0 !important;
        width: auto !important;
    }
    .modalScoreShow {
        width: 100% !important;
        flex-wrap: wrap;
        flex: 1 !important;
    }
    .VideocloseIcon {
        position: absolute;
        top: 0;
        right: 10px;
    }
    .scoreDeatils {
        flex: unset !important;
        width: 100%;
    }
    .modalVideoOuter video {
        object-fit: cover;
        object-position: center;
    }
    .modalFooter {
        flex-wrap: wrap;
        gap: 15px;
        padding: 0 !important;
        justify-content: center !important;
        width: 100% !important;
    }
    .videoController {
        gap: 5px !important;
    }
    .newGameCol,
    .newGameColSec,
    .game-stats-row .player-profile.player-actionOuter{
        width: 100% !important;
    }
}
@media screen and (max-width: 600px) {
    label.lableValue06,
    label.lableValue04 {
        left: 18%;
    }
    label.lableValue01,
    label.lableValue03 {
        right: 18%;
    }
    label.lableValue05 {
        left: 8%;
    }
    label.lableValue02 {
        right: 8%;
    }
    .dropdown {
        gap: 10px;
    }
    .dropdown li:nth-child(3) {
        width: 156px !important;
    }
}
header ul li a:hover span {
    color: #2c4b5f;
}

/* Added by jd */
.image-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .top-left, .top-right, .bottom-left, .bottom-right {
    position: absolute;
    padding: 10px;
    color: white;
    border-radius: 15px;
  }
  
  .top-left {
    margin-top: -2px;
    margin-left: -3px;
    top: 0;
    left: 0;
  }
  
  .top-right {
    margin-top: -6px; 
    margin-right: -5px;
    top: 0;
    right: 0;
    /* background-color: #3788b0; */
  }
  .bottom-left {
    margin-top: 151px; 
    margin-left: -5px;
    top: 0;
    left: 0;
  }
  
  .bottom-right {
    margin-top: 130px; 
    margin-right: -5px;
    top: 0;
    right: 0;
  }
  .no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Standard */
    /* cursor: pointer; */
  }

.player-skill-img {
    width: 65% !important;
    height: 100% !important;
    object-fit: contain;
    margin-top: 24px;
}
.ground-label-skill {
    display: flex;
    width: 100%;
    height: 100%;
    border-right: 2px solid #fff;
    cursor: pointer;
}
.gam-logo{
    position: absolute;
    width: 60px;
    height: 60px;
    padding: 10px;
    left: 10px;
    top: 10px;
}
.player_name_inside,
.jersy_number_in {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
}
.jersy_number_in{
    top: unset;
    bottom: 10px;
}
.foot_logo_in{
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 60px;
}