/*
Flaticon icon font: Flaticon
*/
@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Flaticon.woff2") format("woff2"),
        url("../fonts/Flaticon.woff") format("woff"),
        url("../fonts/Flaticon.ttf") format("truetype"),
        url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-facebook-logo:before {
    content: "\f100";
}
.flaticon-twitter:before {
    content: "\f101";
}
.flaticon-youtube:before {
    content: "\f102";
}
.flaticon-instagram:before {
    content: "\f103";
}
.flaticon-linkedin:before {
    content: "\f104";
}
.flaticon-facebook:before {
    content: "\f105";
}
.flaticon-instagram-1:before {
    content: "\f106";
}
.flaticon-youtube-1:before {
    content: "\f107";
}
.flaticon-twitter-1:before {
    content: "\f108";
}
.flaticon-linkedin-1:before {
    content: "\f109";
}
.flaticon-right-arrow:before {
    content: "\f10a";
}
.flaticon-play-button:before {
    content: "\f10b";
}
.flaticon-right:before {
    content: "\f10c";
}
.flaticon-left:before {
    content: "\f10d";
}
.flaticon-copyright:before {
    content: "\f10e";
}
.flaticon-man:before {
    content: "\f10f";
}
.flaticon-categories:before {
    content: "\f110";
}
.flaticon-calendar:before {
    content: "\f111";
}
.flaticon-price-tag:before {
    content: "\f112";
}
